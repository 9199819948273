import { ParsedUrlQuery } from "querystring";
import { GetServerSidePropsContext } from "next";
import dynamic from "next/dynamic";
import PageWrapper from "components/global/PageWrapper/PageWrapper";
import { homeDetailJson } from "utils/helper/jsonLd";
import Head from "components/global/Head/Head";
import { getPageProps, SEOData } from "utils/cms/pageHelper";

const FranchiseHomePage = dynamic(() => import("containers/Franchise/FranchiseHomePage/FranchiseHomePage"), {
  ssr: false,
});
interface Props {
  finalSEO?: SEOData;
}

const defaultSEO = {
  title: "The Best Chicken Franchise Opportunity | Zaxby's Franchise",
  description:
    "Interested in owning a Zaxby's franchise? Come see what life is like as a franchise owner of the best chicken franchise. Learn more about our franchise opportunity.",
};

export const getServerSideProps = async (context: GetServerSidePropsContext<ParsedUrlQuery>) => {
  return getPageProps(context, defaultSEO);
};

const Franchise = ({ finalSEO }: Props) => {
  const jsonLd = homeDetailJson();
  const finalMetaData = { ...finalSEO, jsonLd };
  return (
    <>
      <PageWrapper isFranchise={true}>
        <Head {...finalMetaData} />
        <FranchiseHomePage />
      </PageWrapper>
    </>
  );
};

export default Franchise;
